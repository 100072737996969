<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF LOANS NOT YET APPROVE</h4>

        </v-toolbar-title>
      </v-toolbar>
      Print:
      <v-icon
        class="mr-2"
        color="warning"
        @click="print_data()"
      >
        {{icons.mdiPrinter}}
      </v-icon>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class=" text-uppercase">
              ID
            </th>
            <th class=" text-uppercase">
              Date of Released
            </th>
            <th class=" text-uppercase">
              TYPE OF LOAN
            </th>
            <th class=" text-uppercase">
              STATUS
            </th>
            <th class=" text-uppercase">
              SPECIFY
            </th>
            <th class=" text-uppercase">
              NAME
            </th>
            <th class="text-center text-uppercase">
              AMOUNT
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              View
            </th>
            <th class="text-center text-uppercase">
              Action
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              Dis-approved
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in loans_items"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.loan_transmital!=null?item.loan_transmital.cheque_date:'' }}</td>
            <td>
              {{ item.type_of_loan }}
            </td>
            <td>
              {{ item.type }}
            </td>
            <td>
              {{ item.specify_type_of_loan }}
            </td>
            <td>
              {{ item.members.last_name+', '+item.members.first_name }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              <div v-if="item.status===0">
                <v-chip color="error" dark>NEED VERIFY</v-chip>
              </div>
              <div v-else-if="item.status===1">
                <v-chip color="warning" dark>NEED APPROVAL</v-chip>
              </div>
              <div v-else-if="item.status===2">
                <v-chip color="success" dark
                        v-if="item.transmital_no!=null && (item.loan_transmital!=null&&item.loan_transmital.is_approved===1)">
                  APPROVED
                </v-chip>
                <v-chip color="info" dark
                        v-else-if="item.transmital_no!=null && (item.loan_transmital!=null&&item.loan_transmital.is_approved===2)">
                  NEED CLEARANCE OF FINANCE
                </v-chip>
                <v-chip color="info" dark v-else>NEED TRANSMITAL</v-chip>
              </div>
              <div v-else-if="item.status===4">
                <v-chip color="error" dark>DISAPPROVED</v-chip>
              </div>
            </td>
            <td class="text-center" v-if="position==='ADMIN'">
              <v-icon
                class="mr-2"
                color="success"
                @click="view(item)"
                v-if="item.status!=2 && item.status===1"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <div v-if="item.status===0 && position==='ADMIN'">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="actions(item,1)"
                >
                  {{icons.mdiToggleSwitchOff}}
                </v-icon>
              </div>
              <!--              <div v-else-if="item.status===1 && position==='ADMIN' && (user_id===1||user_id===7)">-->
              <!--                <v-icon-->
              <!--                  class="mr-2"-->
              <!--                  color="warning"-->
              <!--                  @click="actions(item,2)"-->
              <!--                >-->
              <!--                  {{icons.mdiToggleSwitch}}-->
              <!--                </v-icon>-->
              <!--              </div>-->
              <div
                v-else-if="item.status===2 && position==='BRANCH MANAGER' && item.transmital_no!=null&& (item.loan_transmital!=null&&item.loan_transmital.is_approved===1) ">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="loans_dataaa(item)"
                >
                  {{icons.mdiToggleSwitchOff}}
                </v-icon>
              </div>
            </td>
            <td class="text-center" v-if="position==='ADMIN' && item.status!=2">
              <div>
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="dis_approved(item)"
                >
                  {{icons.mdiCancel}}
                </v-icon>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="dialogs" persistent max-width="80%">
      <loans-application
        v-on:data="on_save_loan"
        :key="this.key"
        :value="this.value"
        :member_id="this.member_id"
        :co_maker_id="this.co_maker_id"
        :mode_of_loan="this.mode_of_payment"
        :terms="this.terms"
        :loan_amount="this.amount"
        :share_capital="this.share_capital"
        :regular_savings="this.regular_savings"
        :loan_app_id="this.id"
        :type_of_loan="this.type_of_loan"
        :specify_type_of_loan="this.specify_type_of_loan"
        :purpose_of_loan="this.purpose_of_loan"
        :transmital="this.value.loan_transmital"
      ></loans-application>
      <v-btn color="error" @click="dialogs = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="view_dialog" persistent max-width="80%">
      <loans-application-request
        v-on:data="on_save_loan_reconstrcu"
        :value="this.value"
        :key="this.key"
      ></loans-application-request>
      <v-btn color="error" @click="view_dialog = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiPrinter, mdiToggleSwitchOff, mdiToggleSwitch, mdiEyeCircle, mdiCancel} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import LoansApplication from '@/views/create_interface/LoansApplication'
  import LoansApplicationRequest from '@/views/create_interface/LoansApplicationRequest'
  import moment from "moment";

  export default {

    components: {
      snackBarDialog,
      LoansApplication,
      LoansApplicationRequest,
    },
    mounted() {
      this.list_of_loans_not_ye_approve()
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiToggleSwitchOff,
          mdiToggleSwitch,
          mdiEyeCircle,
          mdiCancel,
        },
      }
    },
    data() {
      return {
        can_print: false,
        loans_items: [],
        dialogs: false,
        view_dialog: false,
        member_id: 0,
        co_maker_id: 0,
        mode_of_payment: '',
        terms: 0,
        amount: 0,
        share_capital: 0,
        regular_savings: 0,
        key: 0,
        id: 0,
        value: {},
        type_of_loan: '',
        specify_type_of_loan: '',
        purpose_of_loan: '',
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_id', 'user_id', 'position', 'branch', 'branch_address', 'branch_contact_no', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loans_data', ['verify_new_loan', 'approve_new_loan', 'dis_approve_new_loan', 'list_of_loans_not_yet_approve']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      actions(item, status) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('status', status);
        data.append('verifier_id', this.user_id);
        data.append('approver_id', this.user_id);
        if (status === 1) {
          this.verify_new_loan(data)
            .then(() => {
              this.list_of_loans_not_ye_approve()
            })
            .catch(error => {
              console.log(error)
            })
        } else if (status === 2) {
          this.approve_new_loan(data)
            .then(() => {
              this.list_of_loans_not_ye_approve()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      dis_approved(item) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('status', 3);
        data.append('approver_id', this.user_id);
        this.dis_approve_new_loan(data)
          .then(() => {
            this.list_of_loans_not_ye_approve()
          })
          .catch(error => {
            console.log(error)
          })
      },
      view(item) {
        this.value = item
        this.view_dialog = true
        this.key += 1
      },
      loans_dataaa(item) {
        this.value = item
        this.id = item.id
        this.member_id = item.member_id
        this.co_maker_id = item.co_maker_id
        this.mode_of_payment = item.mode_of_loan
        this.type_of_loan = item.type_of_loan
        this.specify_type_of_loan = item.specify_type_of_loan
        this.purpose_of_loan = item.purpose_of_loan
        this.terms = item.terms + ' month/s'
        this.amount = item.amount
        this.regular_savings = item.regular_savings
        this.share_capital = item.share_capital
        this.dialogs = true
        this.key += 1
      },
      list_of_loans_not_ye_approve() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('month_of', this.month_of);
        this.list_of_loans_not_yet_approve(data)
          .then(response => {
            this.loans_items = response.data
            this.can_print = true
          })
          .catch(error => {
            console.log(error)
          })
      },
      on_save_loan() {
        this.dialogs = false
        this.list_of_loans_not_ye_approve()
      },
      on_save_loan_reconstrcu() {
        this.view_dialog = false
        this.list_of_loans_not_ye_approve()
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'TYPE OF LOAN', alignment: 'center', style: 'label'},
            {text: 'NAME', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
            {text: 'STATUS', alignment: 'center', style: 'label'},
          ]
        )
        if (this.loans_items.length > 0) {
          this.loans_items.forEach(function (item, index) {

            var status = 'NEED VERIFY'
            if (item.status === 1) {
              status = 'NEED APPROVAL'
            } else if (item.status === 2) {
              status = 'APPROVED'
            }
            array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.type_of_loan, alignment: 'left'},
                {text: item.members.last_name + ', ' + item.members.first_name, alignment: 'left'},
                {
                  text: item.amount,
                  alignment: 'left'
                },
                {text: status, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF LOANS NOT YET APPROVE', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [40, 60, 230, 70, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
